import React from "react";
import { graphql, Link } from "gatsby";
import { css } from "@emotion/core";
import Image from "gatsby-image";

import { IoMdDocument } from "react-icons/io";

import Layout from "../components/layout";

const SketchesPage = ({ data }) => (
  <Layout title="sketches">
    <section>
      <div
        css={css`
          width: 1200px;
          max-width: 100%;
          margin: 0auto;
        `}
      >
        <div
          css={css`
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
            grid-gap: 20px;
          `}
        >
          {data.sketches.nodes.map((sketch) => (
            <div
              key={sketch.id}
              css={css`
                height: 300px;
              `}
            >
              <Link
                css={css`
                  display: block;
                  position: relative;
                  height: 100%;
                `}
                to={`/sketches/${sketch.slug.current}`}
              >
                {sketch.thumbnail ? (
                  <Image
                    fluid={sketch.thumbnail.asset.fluid}
                    css={css`
                      height: 100%;
                    `}
                  />
                ) : (
                  <div
                    css={css`
                      background-color: #ffffff;
                      color: #c71585;
                      font-size: 5em;
                      height: 100%;
                      display: flex;
                      flex-direction: row;
                      justify-content: center;
                      align-items: center;
                      align-content: stretch;
                    `}
                  >
                    <IoMdDocument
                      css={css`
                        padding: 20px;
                      `}
                    />
                  </div>
                )}
                <div
                  css={css`
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    top: 0;
                    left: 0;
                    opacity: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: 0.3s all ease;
                    font-weight: bold;
                    &:hover {
                      background-color: black;
                      opacity: 80%;
                    }
                  `}
                >
                  {sketch.title}
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  </Layout>
);
export const query = graphql`
  query SketchesPageQuery {
    sketches: allSanitySketch {
      nodes {
        slug {
          current
        }
        id
        thumbnail {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
        title
      }
    }
  }
`;

export default SketchesPage;
